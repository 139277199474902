<template>
    <div>
        <HeaderLayout :signbook-merchant-logo="signbookMerchantLogo"></HeaderLayout>
        <v-container v-show="$vuetify.breakpoint.xs">
            <!-- Breadcrumb mobile -->
            <v-row class="mb-3" justify="center">
                <div class="breadcrumb-item mr-2"></div>
                <div class="breadcrumb-item mr-2"></div>
                <div class="breadcrumb-item mr-2"></div>
                <div class="breadcrumb-item mr-2"></div>
                <div class="breadcrumb-item active"></div>
            </v-row>
            <v-row justify="space-around">
                <div>
                    <span class="font-weight-medium title">Montant total dû :</span> <span class="title font-weight-bold light-blue--text accent-4">{{ formatCurrency(totalAmountDue) }}€</span>
                </div>
                <div style="display: flex; justify-content: center; align-content: center; flex-direction: column; cursor: pointer;" @click="showDetailsMobile = !showDetailsMobile">
                    <div v-bind:class="{'recap-details': true, 'font-weight-bold': true, 'recap-details-opened': showDetailsMobile, 'recap-details-closed': !showDetailsMobile}">Détails</div>
                </div>
            </v-row>
            <v-row v-show="showDetailsMobile">
                <v-container class="order-details">
                    <v-row justify="center">
                        <v-col>
                            <div style="color: #fff; font-size: 16px; text-decoration: underline; text-align: center">Votre proposition : </div>
                        </v-col>
                    </v-row>
                    <v-row justify="start" align="center" class="mt-0">
                        <v-col>
                            <div>Montant d'achat<br />{{ formatCurrency(amountAsked) }}</div>
                        </v-col>
                        <v-col>
                            <div>TAEG fixe<br />{{ taeg }}%</div>
                        </v-col>
                    </v-row>
                    <v-row justify="start" align="center" class="mt-0">
                        <v-col v-if="isTradeIn">
                            <div>Montant de reprise<br />{{ formatCurrency(tradeInAmount) }}</div>
                        </v-col>
                        <v-col v-else>
                            <div>Coût du crédit<br />{{ formatCurrency(loanCost) }}</div>
                        </v-col>
                        <v-col v-if="demand.product.insuranceSubscribed">
                            <div>Coût de l'assurance<br />{{ formatCurrency(insuranceCost) }}</div>
                        </v-col>
                        <v-col v-else>
                            <div>Coût du crédit<br />{{ formatCurrency(loanCost) }}</div>
                        </v-col>
                    </v-row>
                    <v-row justify="start" align="center" class="mt-0" v-if="isTradeIn">
                        <v-col>
                            <div>Montant après reprise<br />{{ formatCurrency(amountAfterTradeIn) }}</div>
                        </v-col>
                        <v-col v-if="demand.product.insuranceSubscribed">
                            <div>Coût du crédit<br />{{ formatCurrency(loanCost) }}</div>
                        </v-col>
                        <v-col v-else>
                            <div><strong>{{ duration }} mensualités de</strong><br /><strong>{{ formatCurrency(totalMonthly) }}</strong></div>
                        </v-col>
                    </v-row>
                    <v-row justify="start" align="center" class="mt-0" v-if="demand.product.insuranceSubscribed">
                        <v-col>
                            <div><strong>{{ duration }} mensualités de</strong><br /><strong>{{ formatCurrency(totalMonthly) }}</strong></div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-row>
        </v-container>
        <div v-if="signbookError == false">
            <v-container class="mt-5">
                <v-row class="mb-6">
                    <!-- Breadcrumb desktop -->
                    <v-col cols="12" sm="5" md="5" lg="3" class="pt-0 mb-5" v-show="!$vuetify.breakpoint.xs">
                        <div class="wrapper">
                            <ul class="step-progress pl-2">
                                <li class="step-progress-item is-done"><strong>Vos options</strong></li>
                                <li class="step-progress-item is-done"><strong>Votre identité</strong></li>
                                <li class="step-progress-item is-done"><strong>Votre situation financière</strong></li>
                                <li class="step-progress-item is-done"><strong>Vos pièces justificatives</strong></li>
                                <li class="step-progress-item"><strong>Signature de votre contrat</strong></li>
                            </ul>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="7" md="7" lg="3" class="pt-0" order-lg="1" v-show="!$vuetify.breakpoint.xs">
                        <div id="summary-top" class="pt-2 pb-2">
                            Votre proposition de<br />
                            <strong>FINANCEMENT</strong><br />
                            <img width="100" :src="floaLogoRvb" style="display: inline-block; vertical-align: top;" />
                            <v-badge id="loan-duration-badge"
                                     inline
                                     color="white"
                                     :value="`${ duration }X`"
                                     :content="`${ duration }X`"></v-badge>
                        </div>
                        <div class="summary-middle pt-3 pb-3">
                            Montant d'achat : <span class="display-right">{{ formatCurrency(amountAsked) }}</span>
                        </div>
                        <div class="summary-middle pt-3 pb-3" v-if="!isTradeIn">
                            Nombre de mensualités : <span class="display-right">{{ duration }}</span>
                        </div>
                        <div class="summary-middle pt-3 pb-3" v-if="isTradeIn">
                            Montant de reprise : <span class="display-right">{{ formatCurrency(tradeInAmount) }}</span>
                        </div>
                        <div class="summary-middle pt-3 pb-3" v-if="isTradeIn">
                            Montant après reprise : <span class="display-right">{{ formatCurrency(amountAfterTradeIn) }}</span>
                        </div>
                        <div class="summary-middle pt-3 pb-3">
                            <span :class="{'font-weight-bold': isTradeIn}">{{ duration }} mensualités de</span> : <span :class="{'display-right': true, 'font-weight-bold': isTradeIn}" >{{ formatCurrency(totalMonthly) }}</span>
                        </div>
                        <div class="summary-middle pt-3 pb-3">
                            TAEG fixe : <span class="display-right">{{ taeg }}%</span>
                        </div>
                        <div class="summary-middle pt-3 pb-3" v-if="demand.product.insuranceSubscribed">
                            Coût de l'assurance : <span class="display-right">{{ formatCurrency(insuranceCost) }}</span>
                        </div>
                        <div class="summary-middle pt-3 pb-3">
                            Coût du crédit : <span class="display-right">{{ formatCurrency(loanCost) }}</span>
                        </div>
                        <div id="summary-bottom" class="pt-4 pb-4">
                            <span class="font-weight-medium title">Montant total dû :</span> <span class="title font-weight-bold display-right light-blue--text accent-4">{{ formatCurrency(totalAmountDue) }}</span>
                        </div>
                    </v-col>
                    <v-col cols="12" md="12" class="d-lg-none" v-show="!$vuetify.breakpoint.xs">
                        <hr class="mt-3 mb-3" />
                    </v-col>
                    <Contract
                        :gender="demand.customers[0].gender"
                        :lastName="demand.customers[0].lastName"
                        :is-loading="isLoading">
                    </Contract>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="5" md="5" lg="3" class="pt-0 mb-5" v-show="!$vuetify.breakpoint.xs"/>
                    <v-col cols="12" lg="6" class="pt-0 pl-0 pr-0">
                        <LegalNotice></LegalNotice>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div class="height-center" v-else>
            <error></error>
        </div>
    </div>
</template>

<script>
    import LegalNotice from '../shared/LegalNotice.vue';
    import HeaderLayout from '../shared/HeaderLayout.vue';
    import Contract from '../shared/Contract.vue';
    import {apiRoutes, floaContractClient} from "@/helper";
    import {mapGetters} from "vuex";
    
    export default {
        name: 'SignCCL122436',
        components: {
            LegalNotice,
            HeaderLayout,
            Contract
        },
        props: {
            signbookError: Boolean,
            signbookUrl: String,
            signbookToken: String,
            signbookMerchantLogo: {
                type: String,
                default: ''
            },
            nthSignbook: Object,
            demand: Object,
            isLoading: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                floaLogo: require('../../../public/img/logo_floa.svg'),
                floaLogoRvb: require('../../../public/img/logo_floa_negative.svg'),
                showDetailsMobile: false,
                simulationResult: {
                    "amountAsked": null,
                    "duration": null,
                    "amountMonthly": null,
                    "insuranceMonthly": null,
                    "insuranceRate": null,
                    "totalAmountDueWithInsurance": null,
                    "totalMonthly": null,
                    "totalAmountDue": null,
                    "tnc": null,
                    "apr": null,
                    "fees": null
                }
            }
        },
        methods: {
            formatCurrency(number) {
                return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(number)
            },
        },
        computed: {
            ...mapGetters([
                'getContext'
            ]),
            amountAsked: function () {
                return this.isTradeIn ?
                    this.demand.metas.orderMetadata.totalAmount + this.demand.tradeIn.tradeinAmountEstimated.taxIncluded
                    : this.demand.product.amountAsked;
            },
            tradeInAmount: function () {
                return '-' + this.demand.tradeIn?.tradeinAmountEstimated?.taxIncluded;
            },
            amountAfterTradeIn: function () {
                return this.demand.metas.orderMetadata.totalAmount
            },
            taeg: function () {
                return this.demand.product.taeg;
            },
            duration: function () {
                return this.demand.product.duration;
            },
            insuranceCost: function () {
                if (this.isTradeIn)
                {
                    return (this.simulationResult?.insuranceMonthly / 100) * this.duration;
                }
                return Number.parseFloat(this.demand.product.insuranceSubscribed ? (this.demand.product.insuranceMonthly * this.demand.product.duration) : 0).toFixed(2);
            },
            totalMonthly: function () {
                if (this.isTradeIn)
                {
                    return this.simulationResult?.totalMonthly / 100;
                }
                return this.demand.product.insuranceSubscribed ? this.demand.product.totalMonthlyWithInsurance : this.demand.product.totalMonthly;
            },
            loanCost: function () {
                if (this.isTradeIn)
                {
                    return (this.simulationResult?.totalAmountDue - this.simulationResult?.amountAsked) / 100;
                }
                return Number.parseFloat(this.demand.product.totalAmountDue - this.demand.product.amountAsked).toFixed(2);
            },
            isTradeIn: function() {
                return this.demand.tradeIn && this.demand.tradeIn.tradeinAccepted
            },
            totalAmountDue: function() {
                if (this.isTradeIn)
                {
                    return (this.demand.product.insuranceSubscribed ? this.simulationResult?.totalAmountDueWithInsurance : this.simulationResult?.totalAmountDue) / 100;
                }
                
                return this.demand.product.insuranceSubscribed ? this.demand.product.totalAmountDueWithInsurance : this.demand.product.totalAmountDue;
            }
        },
        async mounted() {
            if(this.isTradeIn)
            {
                this.simulationResult = (await floaContractClient.post(apiRoutes.simulations, { context: this.getContext } )).data;
            }
        }
    }
</script>
<style>
    #app > div {
        background-color: #F3F3F3 !important;
    }

    #app {
        font-family: 'Poppins'
    }

    #loan-duration-badge > span > span {
        margin-top: 14px;
        background-color: #FFF !important;
        border-color: #FFF !important;
        color: #009FFF !important;
        font-weight: bold !important;
        font-size: 0.9em;
        width: 45px;
        height: 21px;
    }
</style>
<style scoped>
    #toolbar {
        text-align: center !important;
    }

    .toolbar-title {
        margin-left: 25px;
        font-size: 0.85em;
        color: #808080;
    }

    .v-sheet.v-card:not(.v-sheet--outlined) {
        box-shadow: none !important;
    }

    #summary-top {
        background-color: #009FFF;
        color: #FFF;
        text-align: center;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }

    .summary-middle {
        padding-left: 15px;
        padding-right: 15px;
        background-color: #FFF;
        font-size: 0.9em;
        border-bottom: 1px solid #d3d3d3;
    }

    #summary-bottom {
        padding-left: 15px;
        padding-right: 15px;
        background-color: #FFF;
        font-size: 0.9em;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }

    .display-right {
        float: right;
    }

    .floa-color {
        color: #009FFF;
    }

    .floa-color-muted {
        color: #009FFF;
        opacity: 0.3;
    }

    .step-progress {
        position: relative;
        list-style: none;
    }

    .step-progress-item {
        position: relative;
        counter-increment: list;
        text-align: right;
        padding-right: 70px;
    }

        .step-progress-item:not(:last-child) {
            padding-bottom: 30px;
            color: #009FFF;
            opacity: 0.3;
        }

        .step-progress-item:last-child {
            color: #009FFF;
        }

        .step-progress-item::before {
            display: inline-block;
            content: '';
            position: absolute;
            right: 0;
            height: 100%;
            width: 19px;
        }

        .step-progress-item::after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            right: 3px;
            width: 27px;
            height: 27px;
            border: 2px solid #009FFF;
            border-radius: 50%;
            background-color: #009FFF;
        }

        .step-progress-item.is-done::before {
            border-left: 5px solid #009FFF;
        }

        .step-progress-item.is-done::after {
            content: "✔";
            font-size: 14px;
            padding-top: 2px;
            color: #FFF;
            text-align: center;
            border: 2px solid #009FFF;
            background-color: #009FFF;
        }

        .step-progress-item.current::before {
            border-left: 2px solid #009FFF;
        }

        .step-progress-item.current::after {
            content: counter(list);
            padding-top: 1px;
            width: 19px;
            height: 18px;
            top: -4px;
            left: -40px;
            font-size: 14px;
            text-align: center;
            color: green;
            border: 2px solid #009FFF;
            background-color: white;
        }

    .step-progress strong {
        display: block;
    }

    .breadcrumb-item {
        border-radius: 50%;
        display: inline-block;
        height: 10px;
        width: 10px;
        background-color: #c9d4d8;
    }

    .active {
        background-color: #009fff;
    }

    .recap-details-closed::before {
        content: url("../../../public/img/recap_plus.svg");
    }

    .recap-details-opened::before {
        content: url("../../../public/img/recap_minus.svg");
    }

    .recap-details::before {
        position: relative;
        left: -2px;
    }

    .recap-details {
        font-size: 12px;
    }

    .order-details {
        color: #fff;
        background-color: #009fff;
        border-radius: 0 0 10px 10px;
        font-size: 16px;
    }
</style>

<style scoped>
    @import '../../assets/css/fonts.css';
</style>
